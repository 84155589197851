import { createApp } from 'vue';
import App from './App';
import { ConfigurationService } from '@nsftx/games-config';
import { assign, capitalize, upperFirst, range } from 'lodash';
import gamesStatic from '@nsftx/games-sdk-js/src/api/gamesStatic';
import sentry from '@nsftx/games-sdk-js/src/utility/sentry';
import { busService, webViewIntegration } from './utility';
import LocalizedLabel from '@/components/LocalizedLabel';
import router from './router';
import store from './store';

const qp = new URLSearchParams(window.location.search);
const tenantId = qp.get('tenantId');
const deliveryPlatform = qp.get('deliveryPlatform') || qp.get('application') || 'web';
const messageProtocol = qp.get('messageProtocol') || qp.get('integrationType') || 'postMessage';
const layout = qp.get('layout') || 'web';
const token = qp.get('token');
const clientType = qp.get('authStrategy') || qp.get('clientType');
const playerId = qp.get('id') || qp.get('playerId');
let productUuid = null;
const deployment = window.location.hostname.includes('nsoft.app') ? 'cf' : 'netlify';

window.addEventListener('message', (event) => {
  if (event.data.action === 'Slave.Load') {
    productUuid = event.data.data.account.uuid;
  }
  if (deliveryPlatform === 'terminal') {
    store.dispatch('handleTerminalMessages', event);
  }
});

const checkLocale = (locale) => {
  switch (locale) {
    case 'sr':
      return 'sr-Latn';
    default:
      return locale;
  }
};

const getSlaveId = (id) => {
  if (id === '/ticket-history/') {
    return `PluginLuckyXTicketHistory`;
  }
  if (id === '/results/') {
    return `PluginLuckyXResults`;
  }
  return `PluginLuckyX`;
};
(async () => {
  const configService = new ConfigurationService({
    environment: process.env.NODE_ENV,
    requiredPaths: [],
    applicationName: capitalize(deliveryPlatform),
    productName: 'LuckyX',
    channel: deliveryPlatform,
    messageProtocol,
    clientAdapters: [
      {
        name: `${upperFirst(messageProtocol)}Adapter`,
        options: {
          slaveId: getSlaveId(window.location.pathname),
          eventPropagation: {
            click: true,
            keydown: '*',
          },
        },
      },
    ],
    translationDomains: ['web'],
    ui: tenantId,
    deployment,
  });
  const config = await configService.getConfiguration();
  config.locale = checkLocale(config.locale);
  const betslipStaticConfig = config.ui?.config?.betslip;
  const betslipConfig = {
    betLayout: betslipStaticConfig?.betLayout ?? 'A',
    channel: deliveryPlatform,
    ticketTypes: betslipStaticConfig?.ticketTypes ?? ['single'],
    rules: config.rules,
    taxes: config.taxes,
    isPossibleWinActive: false,
    isPaymentBetAllowed: betslipStaticConfig?.isPaymentBetAllowed ?? true,
    isPaymentPerBetAllowed: betslipStaticConfig?.isPaymentPerBetAllowed ?? true,
    isFuturePerBetAllowed: betslipStaticConfig?.isFuturePerBetAllowed ?? false,
    isFuturePerTicketAllowed: config.futureBets.value !== 1,
    isTicketCancelAllowed: betslipStaticConfig?.isTicketCancelAllowed ?? true,
    isTicketRebetAllowed: betslipStaticConfig?.isTicketRebetAllowed ?? true,
    isAddButtonActive: true,
    minIncrement: betslipStaticConfig?.minIncrement ?? 0.01,
    futureRounds: range(1, config.futureBets.value + 1),
  };
  await store.dispatch('gamesBetslip/setConfig', betslipConfig);
  await store.dispatch('setDeviceLayout');
  await store.dispatch('setConfig', config);
  await store.dispatch('setBets', config.bets);
  await store.dispatch('setBetLimits', config.betLimit);
  await store.dispatch('setRules', config.rules);
  await store.dispatch('setLayout', layout);
  store.state.config.productUuid = productUuid;
  if (deliveryPlatform === 'terminal') {
    store.state.config.device = store.getters.device;
    store.state.config.betshop = store.getters.betshop;
    store.state.config.companyData = store.getters.companyData;
    store.state.config.account = store.getters.account;
  }
  const translations = await gamesStatic.getTranslations(config.locale);
  await store.dispatch('setTranslations', assign(config.translations, translations)); // sdk action
  if (token && config.ui.config.mode === 'standalone') {
    store.state.config.platformConfig.clientType = clientType;
    store.state.config.platformConfig.playerId = playerId;
    await store.dispatch('user/setUserSessionToken', token);
    await store.dispatch('getPlayer', { config, token });
  }
  busService.init();

  const app = createApp({
    extends: App,
    beforeCreate() {
      if (webViewIntegration.isActive()) {
        if (window.WebAppListener) {
          window.WebAppListener.sendMessage('Slave.Init');
        }
      }
    },
  });

  if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
    sentry.start({
      app,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      sampleRate: 0.01,
      trackComponents: true,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Network Error',
        'timeout of 5000ms exceeded',
        'timeout of 10000ms exceeded',
        'Android is not defined',
        /.*NotAllowedError.*/gm,
        /.*WakeLock.*/gm,
      ],
    });
  }

  app.config.productionTip = false;
  app.use(LocalizedLabel);
  app.use(router).use(store).mount('#app');
})();
